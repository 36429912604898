import api from './api';

class appService { 

    getApps = async ( pageNumber?:number, count?: number, searchText?: string) => {
      try {
        const response = await api.get(
          `/app?${count !== undefined ? (`count=${count}&`) : ''}`
          + `${pageNumber !== undefined ? (`pageNumber=${pageNumber}&`) : ''}`
          + `${searchText && searchText.length > 0 ? (`query=${searchText}&`) : ''}`,
          );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    saveAppData = async (data: any): Promise<any> => {
        return new Promise((resolve, reject) => {
          try {
            api
              .post('/app', data)
              .then((response) => {
                return resolve(response);
              })
              .catch((err) => {
                return resolve(err);
              });
          } catch (error) {
            return reject(error);
          }
        });
      };


      updateAppData = async (id: any, data:any) => {
        return new Promise(async (resolve, reject)=>{
          try {
            api.put(`/app/${id}`, data)
            .then((response)=>{
              return resolve(response);
            })
            .catch((err)=>{
              return resolve(err)
            })
          } catch (error) {
            return reject(error);
          }
        })
      };

      deleteApp = async (id: any) => {
        return new Promise(async (resolve, reject)=>{
          try {
            api.delete(`/app/${id}`)
            .then((response)=>{
              return resolve(response);
            })
            .catch((err)=>{
              return resolve(err)
            })
          } catch (error) {
            return reject(error);
          }
        })
      };

      updateAppId = async (id: any) => {
        return new Promise(async (resolve, reject)=>{
          try {
            api.put(`/app/appid/${id}`)
            .then((response)=>{
              return resolve(response);
            })
            .catch((err)=>{
              return resolve(err)
            })
          } catch (error) {
            return reject(error);
          }
        })
      };

      updateKey = async (id: any) => {
        return new Promise(async (resolve, reject)=>{
          try {
            api.put(`/app/key/${id}`)
            .then((response)=>{
              return resolve(response);
            })
            .catch((err)=>{
              return resolve(err)
            })
          } catch (error) {
            return reject(error);
          }
        })
      };
      

}
export default new appService();
/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  message,
  Switch,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Utility from '../../../Global/Utility';
import Constant from '../../../Global/Constant';
import { ExclamationCircleOutlined, CopyOutlined }  from '@ant-design/icons';
const { Option } = Select;

interface AddKiteApiModalProps {
    isModalVisible: boolean
    editMode: boolean;
    loading: boolean;
    form: FormInstance;
    onCancel: ()=> void;
    onSave: ()=> void;
    generateApiKey: () => void;
}

function AddKiteApiModal(props: AddKiteApiModalProps) {
  const {
    form, editMode, loading, isModalVisible,
    onCancel, onSave, generateApiKey,
  } = props;

  const confirmChangeApiKey = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to change API Key?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() { generateApiKey(); },
    });
  };

  const copyToClipboard = (value: any, entity: string) => {
    navigator?.clipboard?.writeText(value);
    message.info(`Copied ${entity}!`);
  }

  return (
    <Modal
      title={`${editMode ? 'Edit' : 'Add'} Api Details`}
      visible={isModalVisible}
      okText="Save"
      onOk={onSave}
      confirmLoading={loading}
      destroyOnClose
      onCancel={onCancel}
      cancelButtonProps={{ disabled: loading }}
      centered
      closable={false}
      maskClosable={false}
      keyboard={false}
    >
      <Form
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        labelAlign="left"
        colon={false}
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item
              className="mb-2"
              label="App Name"
              name="appName"
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  type: 'string',
                  required: true,
                  message:'App Name is required'
                },
                {
                  pattern: /([A-Za-z])/,
                  message: 'Only special character or only numericals not allowed!',
                },
                {
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Enter name" autoFocus />
            </Form.Item>
          </Col>
          <Col span={24}>            
            <Form.Item
              className="mb-2"
              label="Domain"
              name="domain"
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  type: 'string',
                  required: true,
                  message:'Domain is required'
                },
                {
                  whitespace: true,                  
                },
              ]}
            >
              <Input placeholder="Enter Domain" />
            </Form.Item>
            </Col>
            <Col span={24}> 
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Scope"
              name="scope"
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select api scope"
                optionLabelProp="label"
              >
                {Object.entries(Constant.appScope).map(
                  ([key, value]) => 
                  <Option value={value} label={key}>
                    {key}
                  </Option>
                )}                
              </Select>
            </Form.Item>            
            </Col>
            <Col span={24}> 
              {form.getFieldValue('apiKey') &&
              <Form.Item
                className="mb-2"
                label="API Key"
                name="apiKey"
                normalize={(value) => Utility.trimSpacesInValues(value)}
                rules={[
                  {
                    type: 'string',
                  },
                  {
                    whitespace: true
                  },
                ]}
              >
              <Input 
                bordered={false} 
                disabled={true}
                className="labelText" 
                suffix={
                  <CopyOutlined 
                    onClick={()=> copyToClipboard(form.getFieldValue('apiKey'), 'API Key')}
                  /> 
                }
              />                
              </Form.Item>              
              }
            </Col>
            <Col span={20}>       
              <Form.Item
                className="mb-2"
                label="Status"
                name="active"
                valuePropName="checked"
              >
                <Switch defaultChecked checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>
            </Col>
            {form.getFieldValue('apiKey') && 
              <Col span={20}>            
              <Button danger type="primary" onClick={()=>confirmChangeApiKey()}>Change API Key</Button>           
              </Col>
            }

        </Row>
      </Form>
    </Modal>
  );
}

export default AddKiteApiModal;

/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
    observable, action, makeObservable,
  } from 'mobx';
  import Constant from '../Global/Constant';
  import kiteapiService from '../Services/kiteapiService';
  
  class KiteApiStore {
    kiteapi: Array<any> = [];
  
    total: number = 0;
  
    constructor () {
      makeObservable(this, {
        kiteapi: observable,
        total: observable,
        getKiteApiList: action,
      });
    }
  
    getKiteApiList = async (
      callback: any,
      pageNumber?: number,
      count?: number,
      filterValue?:string,
    ) => {
        kiteapiService.getKiteApi(pageNumber || Constant.defaultPageNumber, count, filterValue)
        .then((response: any) => {
          if (response && response.data) {
            this.kiteapi = response.data.app;
            this.total = response.data.total;
          }
          callback(null);
        })
        .catch((err) => {
          let errorMsg = Constant.defaultErrorMessage;
          if (err && err.response && err.response.data && err.response.data.error) {
            errorMsg = err.response.data.error.message;
          }
          callback(errorMsg);
        });
    }

    addApi = (appName:string, domain:string, scope: Array<number>,
      callback: (error: string | null, data?: string) => void) => {
      kiteapiService.saveApiData({
        appName, domain, scope
      }).then((response) => {
        callback(null, response.data);
      }).catch((err: any) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
    }
  
    updateApi= (id: string, data:any, callback: any) => {
      kiteapiService.updateApiData(id, data)
        .then(() => {
          callback(null);
        }).catch((err) => {
          let errorMsg = Constant.defaultErrorMessage;
          if (err && err.response && err.response.data && err.response.data.error) {
            errorMsg = err.response.data.error.message;
          }
          callback(errorMsg);
        });
    }
  
    updateApiKey = (id: string, callback:  (error: string | null, data?: string) => void) => {
      kiteapiService.updateApiKey(id)
        .then((response: any) => {
          if (response && response.data) {
            callback(null, response.data.apiKey);
          }
        }).catch((err) => {
          let errorMsg = Constant.defaultErrorMessage;
          if (err && err.response && err.response.data && err.response.data.error) {
            errorMsg = err.response.data.error.message;
          }
          callback(errorMsg);
        });
    }

    deleteApp = (id:string, callback: any) => {
      kiteapiService.deleteApi(id)
        .then(() => {
          callback(null);
        }).catch((err) => {
          let errorMsg = Constant.defaultErrorMessage;
          if (err && err.response && err.response.data && err.response.data.error) {
            errorMsg = err.response.data.error.message;
          }
          callback(errorMsg);
        });
    }
  }
  
  export default new KiteApiStore();
  
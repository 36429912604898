import api from './api';

class kiteapiService { 

    getKiteApi = async ( pageNumber?:number, count?: number, searchText?: string) => {
      try {
        const response = await api.get(
          `/kiteapi?${count !== undefined ? (`count=${count}&`) : ''}`
          + `${pageNumber !== undefined ? (`pageNumber=${pageNumber}&`) : ''}`
          + `${searchText && searchText.length > 0 ? (`query=${searchText}&`) : ''}`,
          );
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };     

    saveApiData = async (data: any): Promise<any> => {
      return new Promise((resolve, reject) => {
        try {
          api
            .post('/kiteapi', data)
            .then((response) => {
              return resolve(response);
            })
            .catch((err) => {
              return reject(err);
            });
        } catch (error) {
          return reject(error);
        }
      });
    };


    updateApiData = async (id: any, data:any) => {
      return new Promise(async (resolve, reject)=>{
        try {
          api.put(`/kiteapi/${id}`, data)
          .then((response)=>{
            return resolve(response);
          })
          .catch((err)=>{
            return reject(err)
          })
        } catch (error) {
          return reject(error);
        }
      })
    };

    updateApiKey = async (id: any) => {
      return new Promise(async (resolve, reject)=>{
        try {
          api.put(`/kiteapi/apikey/${id}`)
          .then((response)=>{
            return resolve(response);
          })
          .catch((err)=>{
            return reject(err)
          })
        } catch (error) {
          return reject(error);
        }
      })
    };

    deleteApi = async (id: any) => {
      return new Promise(async (resolve, reject)=>{
        try {
          api.delete(`/kiteapi/${id}`)
          .then((response)=>{
            return resolve(response);
          })
          .catch((err)=>{
            return reject(err)
          })
        } catch (error) {
          return reject(error);
        }
      })
    };
}
export default new kiteapiService();
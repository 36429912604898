const Constant = {
  token: 'token',
  refreshToken: 'refreshToken', 

  defaultPageNumber: 1,
  itemsPerPage: 10,
  notificationDuration: 3,

  defaultErrorMessage: 'Something went wrong, please try again!',
  unAuthorizedError: 'You are not authorized to access this website.',

  userAccess: {
    appAccess: 1,
  },

  userRoles: {
    SuperAdmin: 1,
    Management: 2,
    DeliveryHead: 3,
    ProjectManager: 4,
    TeamLead: 5,
    HRManager: 6,
    TAEmployee: 7,
    SystemAdmin: 8,
    Guest:9,
    ContractEmployee:10
  },

  appScope: {
    Project: 1,
    User: 2,
    Timesheet: 3,
    Client: 4,
    Asset: 5,
    Location: 6,
    BusinessUnits: 7,
    Holidays: 8,
    'Create User': 9,
    'Upload Document': 10,
    Currency: 11
  },

  deleteModalTitle: 'Warning',
  deleteModalContent: 'Do you want to delete this app?',
};

export default Constant;

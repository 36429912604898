/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
    observable, action, makeObservable,
  } from 'mobx';
  import Constant from '../Global/Constant';
  import settingsService from '../Services/settingsService';
  
  class SettingsStore {
    settings: any;
    total: number = 0;
  
    constructor () {
      makeObservable(this, {
        settings: observable,
        total: observable,
        getSettingsList: action,
      });
    }
  
    getSettingsList = async (
      callback: any,
    ) => {
      settingsService.getSettingsList()
        .then((response: any) => {
          if (response && response.data) {
            this.settings = response.data.settings;
            this.total = response.data.settings.length;
          }
          callback(null);
        })
        .catch((err :any) => {
          let errorMsg = Constant.defaultErrorMessage;
          if (err && err.response && err.response.data && err.response.data.error) {
            errorMsg = err.response.data.error.message;
          }
          callback(errorMsg);
        });
    }

    saveData = (
      url: string, 
      base_dn: string, 
      domain: string, 
      username: string, 
      password: string,
      callback: any ) => {

        settingsService.saveAppData({
        url, base_dn, domain, username, password,
      }).then(() => {
        callback(null);
      }).catch((err: any) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
    }
  
    updateData= (id: string, data:any, callback: any) => {
      settingsService.updateAppData(id, data)
        .then(() => {
          callback(null);
        }).catch((err: any) => {
          let errorMsg = Constant.defaultErrorMessage;
          if (err && err.response && err.response.data && err.response.data.error) {
            errorMsg = err.response.data.error.message;
          }
          callback(errorMsg);
        });
    }
}


export default new SettingsStore();

import React from "react";
import { Footer } from "antd/lib/layout/layout";

function FooterComponent() {
  return <div>
    <Footer>
      <div className="d-flex align-items-center">
        <div className="flex-fill">
          <p className="mb-0 text-center">
            Copyright © 2021. Travancore Analytics Pvt Ltd. All rights reserved.
          </p>
        </div>
      </div>
    </Footer>
  </div>;
}

export default FooterComponent;
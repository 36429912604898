/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import {
  observable, action, makeObservable,
} from 'mobx';
import Constant from '../Global/Constant';
import appService from '../Services/appService';

class AppStore {
  app: Array<any> = [];

  total: number = 0;

  constructor () {
    makeObservable(this, {
      app: observable,
      total: observable,
      getAppList: action,
    });
  }

  getAppList = async (
    callback: any,
    pageNumber?: number,
    count?: number,
    filterValue?:string,
  ) => {
    appService.getApps(pageNumber || Constant.defaultPageNumber, count, filterValue)
      .then((response: any) => {
        if (response && response.data) {
          this.app = response.data.app;
          this.total = response.data.total;
        }
        callback(null);
      })
      .catch((err) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
  }

  addApp = (appName:string, callbackUrl:string, domains:string, userRoles: Array<number>, userDetails: Array<number>,
    callback: (error: string | null, data?: string) => void) => {
    appService.saveAppData({
      appName, callbackUrl, domains, userRoles, userDetails
    }).then((response) => {
      callback(null, response.data);
    }).catch((err: any) => {
      let errorMsg = Constant.defaultErrorMessage;
      if (err && err.response && err.response.data && err.response.data.error) {
        errorMsg = err.response.data.error.message;
      }
      callback(errorMsg);
    });
  }

  updateApp= (id: string, data:any, callback: any) => {
    appService.updateAppData(id, data)
      .then(() => {
        callback(null);
      }).catch((err) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
  }

  deleteApp = (id:string, callback: any) => {
    appService.deleteApp(id)
      .then(() => {
        callback(null);
      }).catch((err) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
  }

  updateAppId = (id: string, callback:  (error: string | null, data?: string) => void) => {
    appService.updateAppId(id)
      .then((response: any) => {
        if (response && response.data) {
          callback(null, response.data.appId);
        }
      }).catch((err) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
  }

  updateKey = (id: string, callback:  (error: string | null, data?: string) => void) => {
    appService.updateKey(id)
      .then((response: any) => {
        if (response && response.data) {
          callback(null, response.data);
        }
      }).catch((err) => {
        let errorMsg = Constant.defaultErrorMessage;
        if (err && err.response && err.response.data && err.response.data.error) {
          errorMsg = err.response.data.error.message;
        }
        callback(errorMsg);
      });
  }
}

export default new AppStore();

const Routes = {
  home: '/',
  login: '/login',
  apps: '/apps',
  settings: '/settings',
  kiteapi: '/kiteapi',
  notFound: '/404',
  notAuthorized: '/403',
};

export default Routes;

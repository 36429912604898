import axios, { AxiosRequestConfig } from 'axios';
import authStore from '../Store/authStore';
import Constant from '../Global/Constant';
import Utility from '../Global/Utility';

class API {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.interceptors.request.use(
      (config: any) => {
        const token = localStorage.getItem(Constant.token);
        if (token) {
          const item = { ...config };
          item.headers.Authorization = `Bearer ${token}`;
          return item;
        }
        return config;
      },
      (error: any) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (responseInter: any) => {
        return responseInter;
      },
      (error: any) =>
        new Promise((resolve, reject) => {
          const originalReq = error.config;
          const token = localStorage.getItem(Constant.token);
          const refreshToken = localStorage.getItem(Constant.refreshToken);

          if (refreshToken &&
            error?.response?.status === 403 &&
            !originalReq._retry
          ) {
            const refreshExpireTime = Utility.getRefreshTokenTime();
            if (refreshExpireTime && refreshExpireTime < new Date().getTime()) {
              authStore.signOut();
            } else {
              originalReq._retry = true;
              const res = fetch(`${axios.defaults.baseURL}/user/refresh`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: new Headers({
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + token,
                }),
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                  refreshToken,
                }),
              })
                .then((responseFetch) => {
                  if (responseFetch?.status === 200) {
                    return responseFetch.json();
                  } else {
                    authStore.signOut();
                  }
                })
                .then((responseFetch) => {
                  const { token, refreshToken } = responseFetch;
                  localStorage.setItem(Constant.token, token);
                  localStorage.setItem(Constant.refreshToken, refreshToken);
                  originalReq.headers['Authorization'] = 'Bearer ' + token;
                  return axios(originalReq);
                });

              resolve(res);
            }
          }
          reject(error);
        })
    );
  }

  async get(url: string, config?: AxiosRequestConfig) {
    return axios.get(url, config);
  }

  async post(url: string, data: any) {
    return axios.post(url, data);
  }

  async put(url: string, data?: any) {
    return axios.put(url, data);
  }

  async delete(url: string) {
    return axios.delete(url);
  }
}

export default new API();

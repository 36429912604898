import api from './api';

class settingsService { 

    getSettingsList = async () => {
      try {
        const response = await api.get(`/settings`);
        return Promise.resolve(response);
      } catch (error) {
        return Promise.reject(error);
      }
    };

    saveAppData = async (data: any): Promise<any> => {
        return new Promise((resolve, reject) => {
          try {
            api
              .post('/settings', data)
              .then((response) => {
                return resolve(response);
              })
              .catch((err) => {
                return resolve(err);
              });
          } catch (error) {
            return reject(error);
          }
        });
      };


      updateAppData = async (id: any, data:any) => {
        return new Promise(async (resolve, reject)=>{
          try {
            api.put(`/settings/${id}`, data)
            .then((response)=>{
              return resolve(response);
            })
            .catch((err)=>{
              return resolve(err)
            })
          } catch (error) {
            return reject(error);
          }
        })
      };
}

export default new settingsService();
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';
import authStore from './Store/authStore';
import ApplicationRoutes from './Routing/ApplicationRoutes';

export class App extends React.Component {
  componentDidMount() {
    authStore.addAuthListener((user:any) => {
      authStore.currentUser = user;
      this.forceUpdate();
    });
  }

  render() {
    return (
      <Router>
        <Layout style={{ minHeight: '100vh' , overflowX: 'hidden' }}>
          <ApplicationRoutes />
        </Layout>
      </Router>
    );
  }
}

export default App;

/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  Switch,
  message,
} from 'antd';
import { FormInstance } from 'antd/lib/form';
import Utility from '../../../Global/Utility';
import Constant from '../../../Global/Constant';
import { ExclamationCircleOutlined, CopyOutlined }  from '@ant-design/icons';
const { Option } = Select;

interface AppModalProps {
    isModalVisible: boolean
    editMode: boolean;
    loading: boolean;
    form: FormInstance;
    onCancel: ()=> void;
    onSave: ()=> void;
    generateAppId: () => void;
    generateKey: () => void;
}

function AddAppModal(props: AppModalProps) {
  const {
    form, editMode, loading, isModalVisible,
    onCancel, onSave, generateAppId, generateKey
  } = props;

  const confirmChangeAppId = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to change App Id?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() { generateAppId(); },
    });
  };

  const confirmGenerateKey = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Do you want to generate new keys?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() { generateKey(); },
    });
  };

  const copyToClipboard = (appId: any) => {
    navigator?.clipboard?.writeText(appId);
    message.info('Copied AppId!');
  }

  return (
    <Modal
      title={`${editMode ? 'Edit' : 'Add'} App Details`}
      visible={isModalVisible}
      okText="Save"
      onOk={onSave}
      confirmLoading={loading}
      destroyOnClose
      onCancel={onCancel}
      cancelButtonProps={{ disabled: loading }}
      centered
      closable={false}
      maskClosable={false}
      keyboard={false}
    >
      <Form
        form={form}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        labelAlign="left"
        colon={false}
      >
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Row>
          <Col span={24}> 
            {form.getFieldValue('appId') &&
            <Form.Item
              className="mb-2"
              label="App Id"
              name="appId"
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  type: 'string',
                },
                {
                  whitespace: true
                },
              ]}
            >
              <Input 
                bordered={false} 
                disabled={true}
                className="labelText" 
                suffix={
                  <CopyOutlined 
                    onClick={()=> copyToClipboard(form.getFieldValue('appId'))}
                  /> 
                }
              /> 
              
            </Form.Item> 
             
            }
          </Col>
          <Col span={24}>
            <Form.Item
              className="mb-2"
              label="App Name"
              name="appName"
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  type: 'string',
                  required: true,
                  message:'App Name is required'
                },
                {
                  pattern: /([A-Za-z])/,
                  message: 'Only special character or only numericals not allowed!',
                },
                {
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="Enter name" autoFocus />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              className="mb-2"
              label="Callback URL"
              name="callbackUrl"
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  required: true,
                  message:'Callback URL is required'
                },
              ]}
            >
              <Input placeholder="Enter URL" />
            </Form.Item>
          </Col>
          <Col span={24}>            
            <Form.Item
              className="mb-2"
              label="Domains"
              name="domains"
              normalize={(value) => Utility.trimSpacesInValues(value)}
              rules={[
                {
                  type: 'string',
                },
                {
                  whitespace: true,                  
                },
              ]}
            >
              <Input placeholder="Enter Domains" />
            </Form.Item>
            </Col>
            <Col span={24}> 
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="User Roles"
              name="userRoles"
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select user roles"
                optionLabelProp="label"
              >
                {Object.entries(Constant.userRoles).map(
                  ([key, value]) => 
                  <Option value={value} label={key}>
                    {key}
                  </Option>
                )}                
              </Select>
            </Form.Item>            
            </Col>
            <Col span={20}> 
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="User Details"
              name="userDetails"
              rules={[
                {
                  required: true,
                  message:'User Details required'
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Select user details"
                optionLabelProp="label"
              >
                <Option value={1} label='Name'>Name</Option>
                <Option value={2} label='Email'>Email</Option>
                <Option value={3} label='Profile Image'>Profile Image</Option>
                <Option value={4} label='Role'>Role</Option>
                <Option value={5} label='Contact Number'>Contact Number</Option>
                <Option value={6} label='Department Name'>Department Name</Option>
              </Select>
            </Form.Item>
            </Col>
            <Col span={20}>            
              <Form.Item
                className="mb-2"
                label="Status"
                name="active"
                valuePropName="checked"
              >
                <Switch defaultChecked checkedChildren="Active" unCheckedChildren="Inactive" />
              </Form.Item>              
          </Col>
            {form.getFieldValue('appId') && 
              <Col span={20}>            
              <Button danger type="primary" onClick={()=>confirmChangeAppId()}>Change App Id</Button>
              <Button danger type="primary" className="add-btn-style" onClick={()=>confirmGenerateKey()}>Generate Keys</Button>            
              </Col>
            }

        </Row>
      </Form>
    </Modal>
  );
}

export default AddAppModal;
